// @ts-nocheck
export const isArrays = (array: unknown) =>
  Array.isArray(array) && array.every((row) => Array.isArray(row))

export const elementOrEmpty = (element) => {
  return typeof element === 'undefined' || element === null ? '' : element
}

export const joiner = (data, separator = ',', enclosingCharacter = '"') => {
  return data
    .filter((e) => e)
    .map((row) =>
      row
        .map((element) => elementOrEmpty(element))
        .map((column) => `${enclosingCharacter}${column}${enclosingCharacter}`)
        .join(separator),
    )
    .join(`\n`)
}

export const arrays2csv = (data, headers, separator, enclosingCharacter) =>
  joiner(headers ? [headers, ...data] : data, separator, enclosingCharacter)

export const toCSV = (data, headers, separator, enclosingCharacter) => {
  if (isArrays(data)) return arrays2csv(data, headers, separator, enclosingCharacter)
  throw new TypeError(`Data should be a "String", "Array of arrays" OR "Array of objects" `)
}
