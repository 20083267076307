import {
  getEstimatedMortgagePayment,
  getHomeEquity,
  getHomeEquityValue,
  getHomeValue,
  getInterestRate,
  getMailingAddress,
  getMonthlyPropertyTax,
  getMonthlySavings,
  getNewPayment,
  getNewRate,
  getOwnerOccupied,
  getProperty,
  getPropertyOwner,
  getRemainingLoanBalance,
  getTaxYear,
} from 'utils/formatters/tableFormatters'
import React, { MouseEvent, useState } from 'react'
import DownloadIcon from 'assets/icons/DownloadIcon'
import { Header, SearchResultsTableItem } from 'types'
import FileSaver from 'file-saver'
import { toCSV } from 'utils/csv'

const headers = [
  Header.Property,
  Header.HomeValue,
  Header.InterestRate,
  Header.EstimatedLoanBalance,
  Header.HomeEquity,
  Header.EstimatedMortgagePayment,
  Header.NewRate,
  Header.NewPayment,
  Header.MonthlySavings,
  Header.OwnerOccupied,
  Header.PropertyOwner,
  Header.MonthlyPropertyTax,
  Header.HomeEquityValue,
  Header.MailingAddress,
  Header.TaxYear,
]

type DownloadProps = {
  items: readonly SearchResultsTableItem[]
}

function getCsvData(items: readonly SearchResultsTableItem[]) {
  return [
    headers,
    ...items.map((item) => {
      return [
        getProperty(item),
        getHomeValue(item),
        getInterestRate(item),
        getRemainingLoanBalance(item),
        getHomeEquity(item),
        getEstimatedMortgagePayment(item),
        getNewRate(item),
        getNewPayment(item),
        getMonthlySavings(item),
        getOwnerOccupied(item),
        getPropertyOwner(item),
        getMonthlyPropertyTax(item),
        getHomeEquityValue(item),
        getMailingAddress(item),
        getTaxYear(item),
      ]
    }),
  ]
}

enum Label {
  All = 'all',
  Selected = 'selected',
}

function Download({ items }: DownloadProps) {
  const [showDownloadOptions, setShowDownloadOptions] = useState(false)

  function handleClick(event: MouseEvent<HTMLDivElement>) {
    const {
      dataset: { label },
    } = event.currentTarget

    let filename = ''
    let blob = null

    switch (label) {
      case Label.All:
        filename = `brewfinder-export-all-${new Date()}.csv`
        blob = new Blob(['\uFEFF', toCSV(getCsvData(items), undefined, ',', undefined)], {
          type: 'text/csv;charset=utf-8',
        })
        break
      case Label.Selected:
        filename = `brewfinder-export-selected-${new Date()}.csv`
        blob = new Blob(
          [
            '\uFEFF',
            toCSV(getCsvData(items.filter((item) => item.isSelected)), undefined, ',', undefined),
          ],
          {
            type: 'text/csv;charset=utf-8',
          },
        )
        break
    }

    // @ts-ignore
    FileSaver.saveAs(blob, filename)
  }

  return (
    <th
      className='responsive-table-header-cell px-3 cursor-pointer'
      onClick={() => setShowDownloadOptions((prevDownloadOptions) => !prevDownloadOptions)}
    >
      <DownloadIcon />
      {showDownloadOptions && (
        <div className='absolute top-8 flex flex-col text-left w-24 bg-white shadow-md'>
          <div
            className='p-3 hover:bg-yellow-bajaWhite'
            data-label={Label.All}
            onClick={handleClick}
          >
            All Results
          </div>
          <div
            className='p-3 hover:bg-yellow-bajaWhite'
            data-label={Label.Selected}
            onClick={handleClick}
          >
            Selected
          </div>
        </div>
      )}
    </th>
  )
}

export default React.memo(Download)
