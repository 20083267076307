type CloseIconProps = {
  color?: string
}

function CloseIcon({ color = '#9EB8A5' }: CloseIconProps) {
  return (
    <svg width='11' height='11' viewBox='0 0 11 11' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M9.16439 10.0645L9.22884 10.1288L9.29323 10.0645L10.0645 9.29323L10.129 9.22872L10.0644 9.16427L6.39655 5.50358L10.0645 1.83567L10.1289 1.77122L10.0645 1.70677L9.29323 0.93555L9.22884 0.871163L9.16439 0.935487L5.49642 4.5963L1.83567 0.93555L1.77122 0.871101L1.70677 0.93555L0.93555 1.70677L0.871163 1.77116L0.935487 1.83561L4.59631 5.50358L0.93555 9.16433L0.8711 9.22878L0.93555 9.29323L1.70677 10.0645L1.77122 10.1289L1.83567 10.0645L5.49642 6.4037L9.16439 10.0645Z'
        fill={color}
        stroke='#AEDABA'
        strokeWidth='0.182292'
      />
    </svg>
  )
}

export default CloseIcon
