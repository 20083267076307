import React from 'react'

type PercentageIconProps = {
  className?: string
}

function PercentageIcon({ className }: PercentageIconProps) {
  return (
    <svg
      className={className}
      width='19'
      height='19'
      viewBox='0 0 19 19'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M0 4.05C0 2.78333 0.366667 1.79167 1.1 1.075C1.85 0.358333 2.80833 0 3.975 0C5.14167 0 6.09167 0.358333 6.825 1.075C7.575 1.79167 7.95 2.78333 7.95 4.05C7.95 5.33333 7.575 6.33333 6.825 7.05C6.09167 7.76667 5.14167 8.125 3.975 8.125C2.80833 8.125 1.85 7.76667 1.1 7.05C0.366667 6.33333 0 5.33333 0 4.05ZM16.05 0.299999L6.225 17.8H2.825L12.625 0.299999H16.05ZM3.95 2.1C3.03333 2.1 2.575 2.75 2.575 4.05C2.575 5.36667 3.03333 6.025 3.95 6.025C4.4 6.025 4.75 5.86667 5 5.55C5.25 5.21667 5.375 4.71667 5.375 4.05C5.375 2.75 4.9 2.1 3.95 2.1ZM10.975 14.025C10.975 12.7417 11.3417 11.75 12.075 11.05C12.825 10.3333 13.7833 9.975 14.95 9.975C16.1167 9.975 17.0583 10.3333 17.775 11.05C18.5083 11.75 18.875 12.7417 18.875 14.025C18.875 15.3083 18.5083 16.3083 17.775 17.025C17.0583 17.7417 16.1167 18.1 14.95 18.1C13.7667 18.1 12.8083 17.7417 12.075 17.025C11.3417 16.3083 10.975 15.3083 10.975 14.025ZM14.925 12.075C13.975 12.075 13.5 12.725 13.5 14.025C13.5 15.3417 13.975 16 14.925 16C15.8583 16 16.325 15.3417 16.325 14.025C16.325 12.725 15.8583 12.075 14.925 12.075Z'
        fill='currentColor'
      />
    </svg>
  )
}

export default React.memo(PercentageIcon)
