import { SearchResultsTableItem } from 'types'

function currencyFormatter(value: number) {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  })

  return formatter.format(value)
}

function handleNegativeValues(value: number) {
  return value < 0 ? `(${value}%)` : `${value}%`
}

function capitalize(string: string): string {
  return string
    .split(' ')
    .map((word) => word[0].toUpperCase() + word.slice(1))
    .join(' ')
}

export function getProperty(item: SearchResultsTableItem): string {
  return capitalize(
    `${
      item.address?.houseNumber
    } ${item.address?.streetName?.toLowerCase()} ${item.address?.city?.toLowerCase()}, ${
      item.address?.state
    } ${item.address?.zipCode}`,
  )
}

export function getHomeValue(item: SearchResultsTableItem): string {
  return item.homeValue ? currencyFormatter(item.homeValue) : '--'
}

export function getInterestRate(item: SearchResultsTableItem): string {
  return item.interestRate ? `${item.interestRate}%` : '--'
}

export function getRemainingLoanBalance(item: SearchResultsTableItem): string {
  return item.remainingLoanBalance ? currencyFormatter(item.remainingLoanBalance) : '--'
}

export function getEstimatedMortgagePayment(item: SearchResultsTableItem): string {
  return item.estimatedMortgagePayment ? currencyFormatter(item.estimatedMortgagePayment) : '--'
}

export function getNewRate(item: SearchResultsTableItem): string {
  return item.newRate ? `${item.newRate}%` : 'N/A'
}

export function getNewPayment(item: SearchResultsTableItem): string {
  return item.newPayment ? `${currencyFormatter(item.newPayment)}` : 'N/A'
}

export function getMonthlySavings(item: SearchResultsTableItem): string {
  return item.monthlySavings ? currencyFormatter(item.monthlySavings) : 'N/A'
}

export function getOwnerOccupied(item: SearchResultsTableItem): string {
  return item.ownerOccupied ? 'Yes' : ''
}

export function getHomeEquity(item: SearchResultsTableItem): string {
  return item.equity ? handleNegativeValues(item.equity) : '--'
}

export function getPropertyOwner(item: SearchResultsTableItem): string {
  return capitalize(item.owner?.toLowerCase())
}

export function getMonthlyPropertyTax(item: SearchResultsTableItem): string {
  return currencyFormatter(item.propertyTax)
}

export function getHomeEquityValue(item: SearchResultsTableItem): string {
  return currencyFormatter(item.homeEquityValue)
}

export function getMailingAddress(item: SearchResultsTableItem): string {
  return capitalize(
    `${
      item.mailingAddress.houseNumber
    } ${item.mailingAddress.streetName?.toLowerCase()} ${item.mailingAddress.city?.toLowerCase()}, ${
      item.mailingAddress.state
    } ${item.mailingAddress.zipCode}`,
  )
}

export function getTaxYear(item: SearchResultsTableItem): string {
  return item.taxYear
}
