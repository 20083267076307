import ZipCodeInput from 'components/ux/ZipCodeInput'
import React, { ChangeEvent, Dispatch, SetStateAction, useCallback } from 'react'
import { useHistory } from 'react-router'
import Logo from 'assets/logo.png'
import { PercentageIcon } from 'assets/icons'
import { Slider } from 'components/ux/Slider'
import { InputLabel } from '../../types'

type LandingPageProps = {
  minEquityPercentage: number
  setMinEquityPercentage: Dispatch<SetStateAction<number>>
  minInterestRate: number
  setMinInterestRate: Dispatch<SetStateAction<number>>
  zips: string[]
  setZips: Dispatch<SetStateAction<string[]>>
  handleChange: (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void
}

type FloatInputProps = {
  value: number
  setValue: Dispatch<SetStateAction<number>>
  label: string
}

const FloatInput = React.memo(function ({ value, setValue, label }: FloatInputProps) {
  function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
    const value = parseFloat(event.target.value)
    if (value > 100) return setValue(100)
    return setValue(value)
  }

  return (
    <input
      aria-label={label}
      type='number'
      value={value}
      onChange={handleInputChange}
      className='number-input-landing-page h-12 border border-gray-mercury rounded pl-4 w-full text-gray-midGray'
    />
  )
})

function LandingPage({
  minEquityPercentage,
  setMinEquityPercentage,
  minInterestRate,
  setMinInterestRate,
  zips,
  setZips,
  handleChange,
}: LandingPageProps) {
  const history = useHistory()

  const handleGetMyResults = useCallback(
    function handleGetMyResults() {
      history.push('/home')
    },
    [history],
  )

  return (
    <div className='max-w-container mx-auto w-full'>
      <div className='p-10'>
        <img src={Logo} alt='Lemonbrew Logo' className='mr-3' />
      </div>
      <div className='flex items-center justify-center flex-col'>
        <p className='font-bold text-5xl text-gray-tundora mt-6'>
          Find leads <i className='text-orange-persimmon'>fast</i>
        </p>
        <p className='text-center font-medium text-3xl text-gray-tundora mt-5 leading-10'>
          Type in any zip code and set your filters <br />
          to get actionable leads
        </p>
        <div className='w-152 px-10 py-6 mt-10 rounded'>
          <p className='font-medium mb-1'>Show me homes in</p>
          <ZipCodeInput
            zips={zips}
            setZips={setZips}
            hideIcon
            placeholder='Enter one or more zip codes'
          />
          <div>
            <div className='flex items-center justify-center my-3 mb-6'>
              <p className='font-medium'>
                <span className='relative text-4xl text-gray-tundora top-1 mr-2'>with</span>
                an interest rate above
              </p>
              <div className='flex items-center relative w-24 ml-5 mr-2'>
                <FloatInput label='Rate' value={minInterestRate} setValue={setMinInterestRate} />
                <div className='icon bg-white absolute right-0.5 px-2 h-2/4 flex items-center'>
                  <PercentageIcon className='text-gray-abbey' />
                </div>
              </div>
              <div>
                <Slider
                  label={InputLabel.Rate}
                  value={minInterestRate}
                  onChange={handleChange}
                  min={0}
                  max={100}
                />
              </div>
            </div>
            <div className='flex items-center justify-center my-3'>
              <p className='font-medium leading-12'>
                <span className='relative text-4xl text-gray-tundora top-1 mr-2.5 ml-1.5'>
                  and/or
                </span>
                equity of at least
              </p>
              <div className='flex items-center relative w-24 ml-5 mr-2'>
                <FloatInput
                  label='Equity'
                  value={minEquityPercentage}
                  setValue={setMinEquityPercentage}
                />
                <div className='icon bg-white absolute right-0.5 px-2 h-2/4 flex items-center'>
                  <PercentageIcon className='text-gray-abbey' />
                </div>
              </div>
              <div className='flex-1'>
                <Slider
                  label={InputLabel.Equity}
                  value={minEquityPercentage}
                  onChange={handleChange}
                  min={-100}
                  max={100}
                />
              </div>
            </div>
          </div>
        </div>
        <button
          className='bg-yellow-brightSun py-2 px-16 font-medium text-gray-midGray mt-7 rounded'
          onClick={handleGetMyResults}
          data-testid='submit-btn'
        >
          Get My Leads
        </button>
      </div>
    </div>
  )
}

export default React.memo(LandingPage)
