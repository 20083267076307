import React from 'react'

function AppFooter() {
  return (
    <footer className='bg-black font-bold px-16 py-14 border-t-8 border-green-apple'>
      <div className='text-white text-3xl mb-4'>Sales Tools</div>
      <div className='text-yellow-brightSun text-2xl font-medium'>
        <div className='mb-3'>
          <a href={'#root'}>Salesforce</a>
        </div>
        <div>
          <a href={'#root'}>Sharepoint</a>
        </div>
      </div>
    </footer>
  )
}

export default React.memo(AppFooter)
