import AppHeader from 'components/navigation/AppHeader'
import AppFooter from 'components/navigation/AppFooter'
import { Switch, Route } from 'react-router-dom'
import Home from 'views/Home'
import LandingPage from 'views/Landing'
import useGetReport from 'lib/graphql/hooks/useGetReport'
import Houses from 'assets/graphics/Houses'
import Cloud from 'assets/graphics/Cloud'
import cloud from './assets/graphics/cloud.svg'
// TODO: Move font imports to a single file
import '@fontsource/poppins/500.css'
import '@fontsource/poppins'
import '@fontsource/asap'

const backgroundStyle = { backgroundImage: `url(${cloud})` }

function App() {
  const {
    data,
    loading,
    isOccupied,
    setIsOccupied,
    minEquityPercentage,
    setMinEquityPercentage,
    minHomeValue,
    setMinHomeValue,
    minInterestRate,
    setMinInterestRate,
    zips,
    setZips,
    handleChange,
  } = useGetReport()

  return (
    <>
      <Switch>
        <Route path='/home'>
          <div className='content bg-no-repeat bg-auto' style={backgroundStyle}>
            <AppHeader />
            <div className={'px-16 py-12'}>
              <Home
                data={data}
                loading={loading}
                isOccupied={isOccupied}
                setIsOccupied={setIsOccupied}
                minEquityPercentage={minEquityPercentage}
                setMinEquityPercentage={setMinEquityPercentage}
                minHomeValue={minHomeValue}
                setMinHomeValue={setMinHomeValue}
                minInterestRate={minInterestRate}
                setMinInterestRate={setMinInterestRate}
                zips={zips}
                setZips={setZips}
                handleChange={handleChange}
              />
            </div>
          </div>
        </Route>
        <Route path='/'>
          <div className='content'>
            <LandingPage
              minEquityPercentage={minEquityPercentage}
              setMinEquityPercentage={setMinEquityPercentage}
              minInterestRate={minInterestRate}
              setMinInterestRate={setMinInterestRate}
              zips={zips}
              setZips={setZips}
              handleChange={handleChange}
            />
            <div className='flex items-end justify-center negative-z-index'>
              <Houses />
              <Cloud />
            </div>
          </div>
        </Route>
      </Switch>
      <AppFooter />
    </>
  )
}

export default App
