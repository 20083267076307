import { SearchResultsTableItem } from 'types'
import { useCallback, useRef, useState } from 'react'

type UseTableInteractions = {
  handleNewRateChange: (apn: string, newRate: number) => void
  handleSelectionToggle: (apn: string) => void
  handleSort: (key: keyof SearchResultsTableItem) => void
  items: readonly SearchResultsTableItem[]
}

enum Direction {
  ascending,
  descending,
}

function sort<T>(items: readonly T[], direction: Direction, sortKey: keyof T) {
  if (items.length > 1) {
    const sortableItems = [...items]
    sortableItems.sort((a, b) => {
      if (a[sortKey] < b[sortKey]) {
        return direction === Direction.ascending ? -1 : 1
      }
      if (a[sortKey] > b[sortKey]) {
        return direction === Direction.ascending ? 1 : -1
      }
      return 0
    })

    return sortableItems
  } else {
    return items
  }
}

export default function useTableInteractions(
  data: readonly SearchResultsTableItem[],
  direction: Direction = Direction.descending,
): UseTableInteractions {
  const sortKey = useRef<keyof SearchResultsTableItem>('interestRate')
  const _direction = useRef<Direction>(direction)
  const [items, setItems] = useState(() => sort(data, _direction.current, sortKey.current))

  const handleSort = useCallback(function (key: keyof SearchResultsTableItem) {
    let direction = Direction.ascending

    if (sortKey.current === key && _direction.current === Direction.ascending) {
      direction = Direction.descending
    }
    setItems((items) => sort(items, direction, key))
    _direction.current = direction
    sortKey.current = key
  }, [])

  const handleNewRateChange = useCallback(
    function (apn: string, newRate: number) {
      const itemsCopy = [...items]
      const propertyIndex = itemsCopy.findIndex((item) => item.apn === apn)
      const property = itemsCopy[propertyIndex]
      const principal = property.firstOpenLienMortgageAmount
      const termOfLoan = property.firstOpenLienTerm || 30
      const annualInterestRate = property.firstOpenLienInterestRate
      const percentageRate = annualInterestRate / 1200
      const lengthOfLoan = 12 * termOfLoan
      const monthlyPayment =
        (principal * percentageRate) / (1 - Math.pow(1 + percentageRate, -lengthOfLoan))

      const newPercentageRate = newRate / 1200
      const newMonthlyPayment =
        (principal * newPercentageRate) / (1 - Math.pow(1 + newPercentageRate, -lengthOfLoan))

      itemsCopy[propertyIndex] = {
        ...property,
        newRate,
        newPayment: Math.round(newMonthlyPayment),
        monthlySavings: Math.round(monthlyPayment - newMonthlyPayment) || null,
      }
      setItems(itemsCopy)
    },
    [items],
  )

  const handleSelectionToggle = useCallback(
    function (apn: string) {
      const itemsCopy = [...items]
      const itemIndex = itemsCopy.findIndex((item) => item.apn === apn)

      itemsCopy[itemIndex] = {
        ...itemsCopy[itemIndex],
        isSelected: !itemsCopy[itemIndex].isSelected,
      }

      setItems(itemsCopy)
    },
    [items],
  )

  return { items, handleNewRateChange, handleSelectionToggle, handleSort }
}
