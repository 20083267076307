export enum Header {
  Property = 'Property',
  HomeValue = 'Home Value',
  InterestRate = 'Interest Rate',
  EstimatedLoanBalance = 'Est. Loan Balance',
  HomeEquity = 'Home Equity',
  EstimatedMortgagePayment = 'Est. Mortgage Payment',
  NewRate = 'New Rate',
  NewPayment = 'New Payment',
  MonthlySavings = 'Monthly Savings',
  OwnerOccupied = 'Owner Occupied',
  PropertyOwner = 'Property Owner',
  MonthlyPropertyTax = 'Monthly Property Tax',
  HomeEquityValue = 'Home Equity Value',
  MailingAddress = 'Mailing Address',
  TaxYear = 'Tax Year',
}

export type SearchResultsTableItem = {
  isSelected?: boolean
  address: {
    city: string
    houseNumber: string
    state: string
    streetName: string
    zipCode: string
  }
  interestRate: number | null
  estimatedMortgagePayment: number
  equity: number
  homeEquityValue: number
  ownerOccupied: boolean
  monthlySavings: number | null
  homeValue: number
  newRate: number
  newPayment: number
  remainingLoanBalance: number
  firstOpenLienTerm: number
  firstOpenLienMortgageAmount: number
  firstOpenLienMortgageRecordingDate: string
  firstOpenLienInterestRate: number
  secondOpenLienMortgageAmount: number
  secondOpenLienMortgageRecordingDate: string
  secondOpenLienInterestRate: number
  thirdOpenLienMortgageAmount: number
  thirdOpenLienMortgageRecordingDate: string
  thirdOpenLienInterestRate: number
  fourthOpenLienMortgageAmount: number
  fourthOpenLienMortgageRecordingDate: string
  fourthOpenLienInterestRate: number
  owner: string
  apn: string
  mailingAddress: {
    city: string
    houseNumber: string
    state: string
    streetName: string
    zipCode: string
  }
  taxYear: string
  propertyTax: number
}

export enum InputLabel {
  Rate = 'rateSlider',
  Equity = 'equitySlider',
  Occupancy = 'occupancy',
}
