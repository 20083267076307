import { gql } from '@apollo/client'

const GET_REPORT_QUERY = gql`
  query GetReportQuery(
    $zips: [String!]!
    $minInterestRate: Float!
    $maxInterestRate: Float!
    $minHomeValue: Int!
    $maxHomeValue: Int!
    $minEquityPercentage: Float!
    $isOccupied: Boolean
  ) {
    properties(
      zips: $zips
      minInterestRate: $minInterestRate
      maxInterestRate: $maxInterestRate
      minHomeValue: $minHomeValue
      maxHomeValue: $maxHomeValue
      minEquityPercentage: $minEquityPercentage
      isOccupied: $isOccupied
    ) {
      address {
        city
        houseNumber
        state
        streetName
        zipCode
      }
      interestRate
      estimatedMortgagePayment
      equity
      ownerOccupied
      monthlySavings
      homeValue
      homeEquityValue
      firstOpenLienTerm
      firstOpenLienMortgageAmount
      firstOpenLienMortgageRecordingDate
      firstOpenLienInterestRate
      newPayment
      newRate
      secondOpenLienMortgageAmount
      secondOpenLienMortgageRecordingDate
      secondOpenLienInterestRate
      thirdOpenLienMortgageAmount
      thirdOpenLienMortgageRecordingDate
      thirdOpenLienInterestRate
      fourthOpenLienMortgageAmount
      fourthOpenLienMortgageRecordingDate
      fourthOpenLienInterestRate
      remainingLoanBalance
      owner
      apn
      mailingAddress {
        city
        houseNumber
        state
        streetName
        zipCode
      }
      taxYear
      propertyTax
    }
  }
`

export default GET_REPORT_QUERY
