import React from 'react'

function SearchIcon() {
  return (
    <svg width='18' height='18' viewBox='0 0 18 18' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M16.8333 16.8333L13.095 13.0883L16.8333 16.8333ZM15.1667 8.08333C15.1667 9.96195 14.4204 11.7636 13.092 13.092C11.7636 14.4204 9.96195 15.1667 8.08333 15.1667C6.20472 15.1667 4.40304 14.4204 3.07466 13.092C1.74628 11.7636 1 9.96195 1 8.08333C1 6.20472 1.74628 4.40304 3.07466 3.07466C4.40304 1.74628 6.20472 1 8.08333 1C9.96195 1 11.7636 1.74628 13.092 3.07466C14.4204 4.40304 15.1667 6.20472 15.1667 8.08333V8.08333Z'
        stroke='#4C4D4F'
        strokeWidth='2'
        strokeLinecap='round'
      />
    </svg>
  )
}

export default React.memo(SearchIcon)
