import React from 'react'

function DownloadIcon() {
  return (
    <svg width='19' height='15' viewBox='0 0 19 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M17.8859 8.36475C17.5554 8.36475 17.2832 8.60872 17.2832 8.90498V13.2617H1.20535V8.90498C1.20535 8.60872 0.933173 8.36475 0.602673 8.36475C0.272172 8.36475 0 8.60872 0 8.90498V13.802C0 14.0982 0.272172 14.3422 0.602673 14.3422H17.8859C18.2164 14.3422 18.4886 14.0982 18.4886 13.802V8.90498C18.4886 8.59129 18.2164 8.36475 17.8859 8.36475Z'
        fill='#35495E'
      />
      <path
        d='M8.84599 10.3168C8.96263 10.4214 9.11815 10.4737 9.27368 10.4737C9.42921 10.4737 9.58474 10.4214 9.70139 10.3168L13.2591 7.12769C13.4924 6.91856 13.4924 6.58745 13.2591 6.37832C13.0258 6.1692 12.6564 6.1692 12.4231 6.37832L9.8958 8.64385V0.540236C9.8958 0.243975 9.62363 0 9.29313 0C8.96263 0 8.69045 0.243975 8.69045 0.540236V8.66127L6.12423 6.3609C5.89094 6.15177 5.52154 6.15177 5.28825 6.3609C5.05496 6.57002 5.05496 6.90114 5.28825 7.11026L8.84599 10.3168Z'
        fill='#35495E'
      />
    </svg>
  )
}

export default React.memo(DownloadIcon)
