import { ChangeEvent, useCallback, useMemo, useState } from 'react'
import { useQuery } from '@apollo/client'
import GET_REPORT_QUERY from 'lib/graphql/queries/getReport'
import useDebounce from 'lib/ux/hooks/useDebounce'
import { InputLabel, SearchResultsTableItem } from '../../../types'
import { assertUnreachable } from '../../../utils'

const maxHomeValue = 999999999
const maxInterestRate = 100
const initialData: SearchResultsTableItem[] = []

function useGetReport() {
  const [isOccupied, setIsOccupied] = useState<boolean | undefined>(undefined)
  const [minEquityPercentage, setMinEquityPercentage] = useState(0)
  const [minHomeValue, setMinHomeValue] = useState(0)
  const [minInterestRate, setMinInterestRate] = useState(0)
  const [zips, setZips] = useState<string[]>([])

  const vars = useMemo(
    () => ({
      variables: {
        isOccupied: isOccupied ?? undefined,
        minHomeValue,
        maxHomeValue,
        minInterestRate,
        maxInterestRate,
        minEquityPercentage,
        zips,
      },
      skip: zips.length === 0,
    }),
    [isOccupied, minHomeValue, minInterestRate, minEquityPercentage, zips],
  )

  const variables = useDebounce(vars, 1500)

  const { loading, error, data } = useQuery(GET_REPORT_QUERY, variables)

  const handleChange = useCallback(function (
    event: ChangeEvent<HTMLInputElement | HTMLSelectElement>,
  ) {
    // @ts-ignore
    const { ariaLabel, value }: { ariaLabel: InputLabel; value: string } = event.target

    switch (ariaLabel) {
      case InputLabel.Rate:
        return setMinInterestRate(parseFloat(value))
      case InputLabel.Equity:
        return setMinEquityPercentage(parseFloat(value))
      case InputLabel.Occupancy:
        return setIsOccupied(JSON.parse(value))
    }
    assertUnreachable(ariaLabel)
  },
  [])

  return {
    data: data?.properties || initialData,
    loading,
    error,
    isOccupied,
    setIsOccupied,
    minEquityPercentage,
    setMinEquityPercentage,
    minHomeValue,
    setMinHomeValue,
    minInterestRate,
    setMinInterestRate,
    zips,
    setZips,
    handleChange,
  }
}

export default useGetReport
