import React, { ChangeEventHandler } from 'react'

type SliderProps = {
  value: number
  onChange: ChangeEventHandler<HTMLInputElement>
  label: string
  min: number
  max: number
}

export const Slider = React.memo(function ({ value, onChange, min, max, label }: SliderProps) {
  return (
    <input
      type='range'
      className='appearance-none w-28 h-2 bg-green-haze rounded outline-none slider-thumb'
      step={0.5}
      value={value}
      min={min}
      max={max}
      onChange={onChange}
      aria-label={label}
    />
  )
})
