import { PercentageIcon } from 'assets/icons'
import SearchResultsTable from 'components/ux/SearchResultsTable'
import { InputLabel, SearchResultsTableItem } from 'types'
import ZipCodeInput from 'components/ux/ZipCodeInput'
import React, { ChangeEvent, Dispatch, SetStateAction, useEffect, useRef, useState } from 'react'
import { Slider } from 'components/ux/Slider'

type NumberInputProps = {
  label: string
  value: number
  setValue: Dispatch<SetStateAction<number>>
}

const NumberInput = React.memo(function ({ label, value, setValue }: NumberInputProps) {
  const inputEl = useRef<HTMLInputElement>(null)
  const [show, setShow] = useState<boolean>(false)

  useEffect(() => {
    setShow(true)
    inputEl?.current?.focus()
    if (value === 0) setShow(false)
  }, [value])

  function handleInputChange(event: ChangeEvent<HTMLInputElement>) {
    const value = parseFloat(event.target.value)
    if (value > 100) return setValue(100)
    return setValue(value)
  }

  function handleShowInput() {
    if (value === 0) setShow(true)
    setTimeout(() => {
      inputEl.current?.focus()
    }, 0)
  }

  function handleInputBlur() {
    inputEl?.current?.value === '0' && setShow(false)
  }

  return (
    <div
      className='flex text-green items-center relative w-36 h-12 border border-gray-mercury rounded pl-4 text-gray-midGray number-input-container'
      onClick={handleShowInput}
      data-testid='number-input-container'
    >
      {show ? (
        <span className='mr-1 text-sm'>{label}:</span>
      ) : (
        <span className='mr-1 text-sm'>Any {label}</span>
      )}
      <input
        type='number'
        hidden={!show}
        value={value}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        className='number-input-search-page w-full text-sm'
        max={100}
        ref={inputEl}
        placeholder={`Any ${label}`}
        aria-label={label}
      />
      <div className='icon bg-white absolute right-0 px-1 h-full flex items-center'>
        <PercentageIcon className='text-gray-abbey' />
      </div>
    </div>
  )
})

type HomeProps = {
  minHomeValue: number
  setMinHomeValue: Dispatch<SetStateAction<number>>
  isOccupied: boolean | undefined
  setIsOccupied: Dispatch<SetStateAction<boolean | undefined>>
  minInterestRate: number
  setMinInterestRate: Dispatch<SetStateAction<number>>
  minEquityPercentage: number
  setMinEquityPercentage: Dispatch<SetStateAction<number>>
  zips: string[]
  setZips: Dispatch<SetStateAction<string[]>>
  loading: boolean
  data: SearchResultsTableItem[]
  handleChange: (event: ChangeEvent<HTMLInputElement | HTMLSelectElement>) => void
}

function Home({
  isOccupied,
  minInterestRate,
  setMinInterestRate,
  minEquityPercentage,
  setMinEquityPercentage,
  zips,
  setZips,
  loading,
  data,
  handleChange,
}: HomeProps) {
  return (
    <div className='max-w-container mx-auto mt-7'>
      <div className='max-w-container mx-auto flex items-center justify-between flex-wrap'>
        <div className='block mt-1 w-full sm:flex sm:items-center sm:w-auto sm:mt-0'>
          <div className='text-xl'>
            <span className='font-semibold text-green-haze mr-1' aria-label='results-length'>
              {data.length}
            </span>
            <span className='font-medium text-gray-rollingStone mr-3'>Results in</span>
          </div>
        </div>
      </div>
      <div className='flex justify-between items-center'>
        <div className='flex justify-between flex-col items-center mb-10 w-full md:flex-row'>
          <div className='flex items-center relative min-w-18 mr-5 '>
            <ZipCodeInput
              zips={zips}
              setZips={setZips}
              placeholder={'Enter one or more zip codes'}
            />
          </div>
          <div className='flex items-center flex-col md:flex-row'>
            <div className='flex items-center flex-col'>
              <div className='flex w-64 items-center'>
                <NumberInput label='Rate' value={minInterestRate} setValue={setMinInterestRate} />
                <div className='ml-2'>
                  <Slider
                    label={InputLabel.Rate}
                    value={minInterestRate}
                    onChange={handleChange}
                    min={0}
                    max={100}
                  />
                </div>
              </div>
            </div>
            <div className='flex items-center flex-col mx-8'>
              <div className='flex w-64 items-center'>
                <NumberInput
                  label='Equity'
                  value={minEquityPercentage}
                  setValue={setMinEquityPercentage}
                />
                <div className='ml-2'>
                  <Slider
                    label={InputLabel.Equity}
                    value={minEquityPercentage}
                    onChange={handleChange}
                    min={-100}
                    max={100}
                  />
                </div>
              </div>
            </div>
            <div className='flex items-center flex-col'>
              <div className='flex w-full items-center'>
                <div className='select-occupancy-container flex text-green items-center relative w-max h-13 border border-gray-mercury rounded pl-4 text-gray-midGray input-container'>
                  <select
                    name={InputLabel.Occupancy}
                    value={String(isOccupied)}
                    className='occupancy-select mr-2 font-me'
                    onChange={handleChange}
                    aria-label='occupancy'
                  >
                    <option value='null' aria-label='occupancy-option'>
                      Any occupancy
                    </option>
                    <option value='true' aria-label='occupancy-option'>
                      Occupied by owner
                    </option>
                    <option value='false' aria-label='occupancy-option'>
                      Not occupied by owner
                    </option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {loading ? (
        <div className='flex justify-center'>
          <div className='loader ease-linear rounded-full border-8 border-t-8 h-40 w-40' />
        </div>
      ) : (
        <SearchResultsTable data={data} />
      )}
    </div>
  )
}

export default React.memo(Home)
