import React from 'react'

type SortIconProps = {
  className?: string
}

function SortIcon({ className }: SortIconProps) {
  return (
    <svg
      className={className}
      width='14'
      height='17'
      viewBox='0 0 14 17'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M5 5.9585L5 -3.93402e-07L3 -4.80825e-07L3 5.9585L6.9322e-07 5.9585L4 9.35L8 5.9585L5 5.9585ZM6 11.0415L9 11.0415L9 17L11 17L11 11.0415L14 11.0415L10 7.65L6 11.0415Z'
        fill='#9EB8A5'
      />
    </svg>
  )
}

export default React.memo(SortIcon)
