import React from 'react'

function Cloud() {
  return (
    <svg
      width='1920'
      viewBox='0 0 1920 287'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
      className='w-full'
    >
      <path
        d='M1871.24 161.989C1701.95 54.8367 1489.49 -1.89098 1258.67 30.0446C1173.56 41.8103 1092.13 64.7115 1006.41 73.956C915.492 83.8308 826.407 66.6024 741.302 45.8023C644.257 22.0607 544.458 -5.88293 441.903 0.840354C362.92 6.09292 290.367 31.5153 216.588 51.6852C122.299 77.7379 24.0302 95.8067 -76.3816 105.681C-167.609 114.506 -252.715 123.12 -313.941 177.326C-350.677 209.892 -355.882 248.971 -341.8 287H1979C1965.84 234.895 1924.2 195.395 1871.24 161.989Z'
        fill='#F2F9F5'
      />
    </svg>
  )
}

export default React.memo(Cloud)
